// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

let $ = require('jquery')
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


require('@popperjs/core')
import * as bootstrap from 'bootstrap'

let change = false

// window.bootstrap = bootstrap;

let check_reinvestments = function (value, id) {
    console.log(value, id);
    let submit_reinvestment = $('#submit_reinvestment_gr' + id)
    let show_message = $('#show_message_gr' + id)
    if (value < 0) {
        submit_reinvestment.attr("disabled", true);
        show_message.css('visibility', 'visible');
    } else {
        submit_reinvestment.attr("disabled", false);
        show_message.css('visibility', 'hidden');
    }
}

let Utility = {
    menu: function() {
        let nemo_value_inputs = $('input[id^=nemo_value_]').map(function(){return $(this)}).get();
        let bond_inputs = $('input[id^=bond_]').map(function(){return $(this)}).get();


        nemo_value_inputs.forEach(function(input) {
            let grand_roll_id = input.attr('id').match(/gr(\d+)/)[1]

            input.on('focusout', function () {
                if (input.val() == "") {
                    input.val(0)
                }

                $('#remaining_value_gr'+grand_roll_id).val($('#total_value_gr'+grand_roll_id).val() - $('#nemo_value_gr'+grand_roll_id).val() - $('input[id^=bond_gr'+ grand_roll_id +'_]').map(function(){return $(this).val()}).get().reduce(function(a, b) { return parseInt(a) + parseInt(b) }, 0))
                check_reinvestments($('#remaining_value_gr'+grand_roll_id).val(), grand_roll_id)

            })

            input.on('input', function() {
                $('#remaining_value_gr'+grand_roll_id).val($('#total_value_gr'+grand_roll_id).val() - $('#nemo_value_gr'+grand_roll_id).val() - $('input[id^=bond_gr'+ grand_roll_id +'_]').map(function(){return $(this).val()}).get().reduce(function(a, b) { return parseInt(a) + parseInt(b) }, 0))
                check_reinvestments($('#remaining_value_gr'+grand_roll_id).val(), grand_roll_id)

                change = true
            })


        })

        bond_inputs.forEach(function(input) {
            let grand_roll_id = input.attr('id').match(/gr(\d+)/)[1]
            input.on('focusout', function () {
                if (input.val() == "") {
                    input.val(0)
                }
                    //todo = tohle hodit do externi funkce  a neopakovat to
                    $('#remaining_value_gr'+grand_roll_id).val($('#total_value_gr'+grand_roll_id).val() - $('#nemo_value_gr'+grand_roll_id).val() - $('input[id^=bond_gr'+ grand_roll_id +'_]').map(function(){return $(this).val()}).get().reduce(function(a, b) { return parseInt(a) + parseInt(b) }, 0))
                    check_reinvestments($('#remaining_value_gr'+grand_roll_id).val(), grand_roll_id)


            })

            input.on('input', function() {
                $('#remaining_value_gr'+grand_roll_id).val($('#total_value_gr'+grand_roll_id).val() - $('#nemo_value_gr'+grand_roll_id).val() - $('input[id^=bond_gr'+ grand_roll_id +'_]').map(function(){return $(this).val()}).get().reduce(function(a, b) { return parseInt(a) + parseInt(b) }, 0))
                check_reinvestments($('#remaining_value_gr'+grand_roll_id).val(), grand_roll_id)

                change = true

                // $('#colapse-button-gr'+grand_roll_id).attr('data-bs-toggle', 'modal')
                // $('#colapse-button-gr'+grand_roll_id).attr('data-bs-target', '#exampleModal')
                // $('#top-colapse-button-gr'+grand_roll_id).attr('data-bs-toggle', 'modal')
                // $('#top-colapse-button-gr'+grand_roll_id).attr('data-bs-target', '#exampleModal')
                // $('#modal-ok').attr('data-bs-toggle', 'collapse')
                // $('#modal-ok').attr('data-bs-target', '#flush-collapse'+grand_roll_id)

            })


        })
    },
    spinner: function() {
        let $button = $('#investment-submit');
        let $spinner = $('#page-spinner');

        $button.on('click touch', function() {
            $spinner.toggleClass('d-none');
        });
    },
    closeReinvestment: function() {
        // var collapseElementList = $('[data-bs-toggle="collapse"]').map(function(){return $(this)}).get();
        // $('#modal-ok').on('click touch', function (item) {
        //     myModal.hide()
        // })
        // var collapseList = collapseElementList.map(function (collapseEl) {
        //     return new bootstrap.Collapse(collapseEl)
        // })

        // collapseElementList.forEach(function (item) {
        //     console.log(item);
        //     item.on('click touch', function() {
        //         // alert('haha')
        //     })
        // })


        // let myCollapsible = document.getElementById('flush-collapse2')
        // console.log(myCollapsible)
        let collapsibleList = $('[id^=flush-collapse]').map(function(){return $(this)}).get();
        let myModal = new bootstrap.Modal(document.getElementById('exampleModal'), {
            keyboard: false
        })

        // let myCollapsible3 = document.getElementById('flush-collapse3')
        // var bsCollapse = new bootstrap.Collapse(myCollapsible3, {
        //     toggle: false
        // })
        collapsibleList.forEach(function (item) {
            item[0].addEventListener('hide.bs.collapse', function () {
                if(change) {
                    myModal.show()
                    change = false
                }

            })
        })
        // myCollapsible
        // let closing_buttons = $('input[id^=nemo_value_]').map(function(){return $(this)}).get();
        // nemo_value_inputs.forEach(function(input) {}
    },
    init: function() {
        this.menu();
        this.spinner();
        this.closeReinvestment();
    }
};

document.addEventListener("turbolinks:load", function() {
    console.log('test1');
    $(document).ready(function (e) {
        Utility.init();
        console.log('test2');
    });
})



require('../../assets/stylesheets/stylesheets.js');

const images = require.context('../../assets/images', true)
const imagePath = (name) => images(name, true)
